<template>
  <div class="flex flex-col gap-2">
    <table class="table table-google user-roles">
      <thead>
        <tr>
          <th class="text-nowrap">ID</th>
          <th class="text-nowrap">Name</th>
          <th class="text-nowrap row-fit">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="permit in permits" :key="permit.id">
          <td class="row-fit">{{ permit.Id }}</td>
          <td>{{ permit.Name }}</td>
          <td>
            <div class="flex gap-2">
              <b-button
                variant="primary"
                size="sm"
                @click="renamePermit(permit)"
                :disabled="loadingCount > 0"
              >
                Rename
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'UserRoles',
  components: {},
  data() {
    return {
      loadingCount: 0,
      permits: [],
    };
  },
  methods: {
    fetchPermits() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'permits' })
        .then((res) => {
          this.permits = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    renamePermit(permit) {
      const permitName = prompt('Enter new name', permit.Name);
      if (!permitName) {
        return;
      }
      const params = {
        permid: permit.Id,
        name: permitName,
      };
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'permitName' })
        .send({ params })
        .then(() => {
          this.fetchPermits();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchPermits();
  },
};
</script>

<style lang="scss" scoped>
  .user-roles {
    max-width: 500px;
  }
</style>
